* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  --theme: #466b74;
  --text: #fff;
}

a {
  color: var(--text);
  text-decoration: none;
}

a .text {
  text-decoration: underline;
}

a:hover .text {
  text-decoration: none;
}

.page {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  justify-content: center;

  /* background-image: url(./background.jpg); */
  background-size: cover;
  background-position: 30%;
  background-repeat: no-repeat;
}

.page_content {
  max-width: 1200px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page_content_item {
  display: block;
}

.page_content_item.page_content_item--small {
  flex: 1 0;
}

.page_content_item.page_content_item--large {
  flex: 3 0;
}

.page_content_item#socials {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1rem;
}

.page_content_item#socials > :not(:first-child) {
  margin-left: 1rem;
}

.page_content_item#socials a {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  color: var(--text);
  text-decoration: none;
}

.page_content_item#socials a:hover {
  color: var(--theme);
}

.page_content_item#languages {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1rem;
}

main.page_content_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.backdrop {
  height: 150%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.backdrop_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-image: url(https://arc.net/noise-light.png);
}

.backdrop_background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgb(210, 216, 214);
}

.backdrop_ball-left {
  --width: 300vh;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(77, 133, 124, 1) 0%,
    rgba(77, 133, 124, 1) 40%,
    rgba(130, 172, 168, 1) 50%,
    rgba(178, 196, 194, 1) 61%,
    transparent 70%
  );
  width: var(--width);
  height: var(--width);
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;
  transform: translate(
    calc((var(--width) / 2) * -1),
    calc((var(--width) / 5) * -4)
  );
}

.backdrop_ball-right {
  --width: 450vh;
  background: radial-gradient(
    circle at 50% 50%,
    rgb(0, 0, 0) 0%,
    rgb(1, 16, 17) 60%,
    rgba(135, 166, 163, 1) 65%,
    transparent 68%
  );
  width: var(--width);
  height: var(--width);
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  transform: translate(calc(var(--width) / 2), calc(var(--width) / 1.5));
  /* transform: translate(calc((var(--width) / -2)), calc((var(--width) / -2))); */
}

@media screen and (max-width: 1000px) {
  .backdrop_ball-left {
    --width: 100vw;
  }

  .backdrop_ball-right {
    --width: 200vw;
    transform: translate(calc(var(--width) / 2), calc(var(--width) / 8));
  }
}
